*, *::after, *::before {
    box-sizing: border-box;
}

body {
    margin: 0;
}

:root {
    --bar-width: 35px;
    --bar-height: 2px;
    --hamburger-gap: 7px;
    --foreground: rgba(255,255,255,0.7);
    --background: #fff;
    --hamburger-margin: 20px;
    --animation-timing: 0.5s;
    --hamburger-height: calc(var(--bar-height) * 3 + (var(--hamburger-gap) * 2));
}

.hamburgerMenu {
    --x-width: calc(var(--hamburger-height) * 1.41421356237);
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    width: max-content;
    position: fixed;
    top: var(--hamburger-margin);
    right: var(--hamburger-margin);
    z-index: 200;
    cursor: pointer;
    transition: background-color var(--animation-timing);
    padding: 10px;
}

.hamburgerMenu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
}

/* New CSS for scrolled state */
.scrolled .hamburgerMenu {
    background-color: rgba(0,0,0,0.35); /* Change this to your desired background color */
}

.hamburgerMenu::before,
.hamburgerMenu::after,
.hamburgerMenu input {
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: var(--foreground);
    border-radius: 999px;
    transform-origin: left center;
    transition: all var(--animation-timing);
}

.hamburgerMenu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -2);
}

.hamburgerMenu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 2);
} 

.hamburgerMenu input:checked {
    opacity: 0;
    width: 0;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    opacity: 0;
    background-color: #333;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity var(--animation-timing);
    z-index: 10;
}

.sidebar nav  {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar ul {
    list-style: none;
    text-align: center;
}

.sidebar img {
    width: 60px;
}

.sidebar li {
    padding: 10px;
}

.sidebar ul a {
    color: #eee;
    margin: var(--hamburger-gap);
    font-size: 1.4em;
    text-decoration: none;
    font-weight: 100;
}

.hamburgerMenu.hamburgerMenu:has(input:checked) + .sidebar {
    opacity: 0.95;
    transform: translateX(0);
}

@media screen and (min-width:730px) {
    .Hamb {
        display: none;
    }
}