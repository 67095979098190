.footer {
    position: fixed;
    width: 100vw;
    bottom: 0px;
    left: 0px;
    height: 7vh;
    padding: 2vh auto;
    background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1) 30%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.socials {
    padding-top: 10px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.socialIcon {
    max-height: 35px;
    transition: all 0.2s;

}

.socialIcon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.socialIcon:active {
    transform: scale(0.95);
}