* {
    margin: 0;
    padding: 0;
}

.hero {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(../../assets/hero.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
}

.hero h1 {
    font-size: 4em;
    letter-spacing: 0.3em;
    color: #efefef;
    text-align: start;
    transition: all 0.75s;
    line-height: 1.2;
    width: 600px;
    margin: 20px;
}

@media screen and (max-width: 1025px) {
    .hero h1 {
        max-width: 600px;
    }
    
}

@media screen and (max-width: 500px) {
    .hero h1 {
        font-size: 3.5em;
    }
    
}