* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #fefefe
}

/* FONTS */

@import url("https://use.typekit.net/klb0qrx.css");
@import url("https://use.typekit.net/cbq2hhs.css");

p, a, li, input, textarea, td {
  font-family: "scandia-line-web", sans-serif;
}

h1 {
  font-family: "the-seasons", sans-serif;
  letter-spacing: 0.35em;
  font-weight: 400;
}

h2 {
  font-family: "the-seasons", sans-serif;
  letter-spacing: 0.2em;
  font-size: 3.5em;
  font-weight: 400;
}

h3 {
  font-family: "the-seasons", sans-serif;
  letter-spacing: 0.15em;
  font-size: 2em;
  font-weight: 400;
  margin: 3vh auto;
  text-align: center;
}

/* SCROLLBAR */

/* For Webkit browsers (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}