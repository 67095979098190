.media {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.media p {
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    padding: 1vh;
}

.heading {
    color: #222;
    text-align: center;
    margin: 3vh auto;
}

.mediaCont {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 1300px;
    padding-bottom: 50px;
}

@media (max-width: 1500px) {
    .mediaCont {
        padding-bottom: 6vh;
    }
}

.media a {
    color: #222;
}

.mediaCont iframe {
    border-radius: 10px;
    width: 560px;
    height: 315px;
    margin: 2vh;
    transition: all 0.75s;
}

@media (max-width: 618px) {
    .mediaCont iframe {
        width: 512px;
        height: 288px;
    }
}

@media (max-width: 571px) {
    .mediaCont iframe {
        width: 432px;
        height: 243px;
    }
}

@media (max-width: 484px) {
    .mediaCont iframe {
        width: 320px;
        height: 180px;
    }
}