.contact {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.contact p {
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    padding: 1vh;
}

.heading {
    color: #222;
    text-align: center;
    margin: 3vh auto;
}

.formAndPhoto {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-evenly;
    width: 90vw;
    max-width: 700px;
    margin: 3vh auto;
    background-color: #e9e9e9;
    padding: 2vh;
    border-radius: 10px;
    z-index: 1;
    margin-bottom: 50px;
}

.contactImgCont {
    width: 90vw;
    max-width: 250px;
    min-width: 100px;
    height: 90vw;
    max-height: 250px;
    min-height: 100px;
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
}

.contactImgCont img {
    max-width: 100%;
    transform: translateY(-10%);
}

.facebookDates {
    margin-bottom: 50px;
}

.facebookDates a {
    color: #222;
}