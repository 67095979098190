.footer {
    position: fixed;
    width: 100vw;
    bottom: 0px;
    left: 0px;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socials {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.socialIcon {
    max-height: 35px;
    transition: all 0.2s;
    filter: invert(1);
}

.socialIcon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.socialIcon:active {
    transform: scale(0.95);
}