.contactForm {
    margin: 0;
}

.contactForm input,
.contactForm textarea {
    display: block;
    max-width: 250px;
    min-width: 100px;
    margin: 1em auto;
    outline: none;
    border: 1px solid #777;
    border-radius: 5px;
    padding: 5px;
}

.contactForm input[type="text"],
.contactForm input[type="email"] {
    height: 2.5em;
    width: 90vw;
}

.contactForm textarea {
    height: 5em;
    width: 90vw;
}

.contactForm input[type="submit"] {
    height: 2.5em;
    width: 45vw;
    max-width: 125px;
    color: #222;
}

.contactForm input[type="submit"]:hover {
    cursor: pointer;
    background-color: #ddd;
}   

.contactForm input[type="submit"]:active {
    background-color: #bbb;
}   

.resultMessage {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    font-family: "scandia-line-web", sans-serif;
    text-align: center;
    color: #222;
}

.error {
    color: #222;
}
