.about {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    position: relative;
}

.about p {
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
}

.about .accent {
    font-weight: 600;
}

.heading {
    color: #222;
    text-align: center;
    margin: 3vh auto;
}

.about p {
    width: 90vw;
    max-width: 700px;
}

.about .quote {
    font-style: italic;
    padding-bottom: 10vh;
    margin: 2vh auto;
    font-size: 1.2em;
}

.aboutImgCont {
    width: 90vw;
    max-width: 650px;
    overflow: hidden;
    margin: 3vh auto;
    height: 30vh;
}

.aboutImgCont img {
    max-width: 100%;
    transform: translateY(-20%);
}