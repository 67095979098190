.navbar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: 100;
}

.navbar ul {
    width: 50vw;
    min-width: 600px;
    margin: 5vh auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar ul li {
    display: inline-block;
    user-select: none;
}

.navbar ul a {
    text-decoration: none;
    font-weight: 100;
    font-size: 1.4em;
    transition: all 0.1s;
    color: #efefef;
}

.navbar ul a:hover {
    cursor: pointer;
    color: #555;
}

.navbar ul a:active {
    color: #333;
    transform: scale(0.95);
}

@media screen and (max-width:730px) {
    .navbar {
        display: none;
    }
}