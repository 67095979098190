.fade-in-element {
  opacity: 0;
  animation: fadeIn 0.75s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
